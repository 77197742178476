import Services from "../network/Services";
export const mapApi = "https://maps.googleapis.com/maps/api/js";

export const mapKey = 'AIzaSyBaUn22pwovCzOxH7Uthivbd8_ScMkaEAI';

export const supportEmail='accounts@agingOptions.com'



export const EnquiryRegistrationId = 1;
export const CounterWalkInId = 3;
export const CounterCheckInId = 4;
export const CounterPlanner = 7;
export const PAIDId = 8;
export const UNPAIDId = 9;
export const ConstantEmail = "@aoe.com";
export const ConstantText = "1234567890";
export const ConstantText1 = "(123) 456-7890";
export const ConstantVenue = "Tucson, Arizona";
export const Constantlocate = "USA Location- to be announced soon";
export const ConstantZip = "";



//self checkin
export const selfWalkInId = 2;
export const selfCheckInId = 5;
export const selfPlannerId = 6;


///UserType
export const Registered = 'REGISTERED';
export const checkIn = 'CHECK_IN';
export const PlannerBooking = 'PLANNER_BOOKED';
export const plannerFeePaid = 'PLANNER_FEE_PAID';
export const plannerFeeUnpaid = 'PLANNER_FEE_UNPAID';



export const selfCheckingCheckedIn = 'CHECKEDIN'; 
export const selfPlannerCheckedIn = 'PLANNER';
export const selfClassroomCheckedIn = 'CLASSROOM';
export const LeadStackerToken1 = "6606a0c3-2cbf-4a3a-b12d-484c15559f61";//AO

// export const LeadStackerToken = "4e138444-4400-40f7-bb5c-2588d1b15910";//LPL
export const LeadStackerToken = "6606a0c3-2cbf-4a3a-b12d-484c15559f61";//LPL




export const defaultIPAddress = '127.0.0.1';
export const leadPostApi = (JsonObj) => {
    return new Promise((resolve, reject) => {
        Services.postContactLead(JsonObj).then((res) => {
            resolve(res);
        }).catch((err) => {
            resolve('err');
        });
    });

}
export const leadPostContactApi = (json, aWithDates) => {
    // console.log("dssssssssss",json, aWithDates)
    return new Promise((resolve, reject) => {
        Services.postContactLeadRegist(json, aWithDates).then((res) => {
            let responseId = res?.data?.contact?.id;


            let deleteTag = res?.data?.contact?.tags.filter(itemA => {
                let partsA = itemA.replace(/\s/g, '').toLowerCase().split(',');
                let partialMatch = aWithDates.some(itemB => {
                    let partsB = itemB.replace(/\s/g, '').toLowerCase().split(',');
                    // return partsA.slice(0, -1).join(',') == partsB.slice(0, -1).join(',');
                    return partsA.join(',') == partsB.join(',');
                });
                return partialMatch;
            });

            let json = {
                id: responseId,
                tags: aWithDates,
                deleteTagss: deleteTag,
                contact : "NEW"
            }
            resolve(json);
        }).catch((err) => {
            resolve('err');
        });
    });

}

export const leadCommonApi = async (result, method) => {
    if (method == "POST" && result?.contact != "NEW" && result !== "err") {
        const results = await leadPostApi(result);
        return results
    } else if (method == "DELETE" && result !== "err") {
        const results = await leadDeleteApi(result);
        return results
    }

}
export const leadDeleteApi = (JsonObj) => {
    return new Promise((resolve, reject) => {
        Services.deleteContactLead(JsonObj).then((res) => {
            resolve(res);
        }).catch((err) => {
            resolve('err');
        });
    });
}

export const GetResponse = (JsonObj, userData) => {
    // console.log("hgfyyftydfhgfhf", JsonObj, userData)
    return new Promise((resolve, reject) => {
        let currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        
        // let aWithDates = JsonObj.tag.map(item => `${item},${formattedDate}`);
        let aWithDates = JsonObj.tag.map(item => `${item}`);
        // console.log("datessss",aWithDates)
        Services.getContactLead(JsonObj.email).then((res) => {
            let responseId = res?.data?.contacts[0]?.id;

            let deleteTag = res?.data?.contacts[0]?.tags.filter(itemA => {
                let partsA = itemA.replace(/\s/g, '').toLowerCase().split(',');

                let partialMatch = aWithDates.some(itemB => {
                    let partsB = itemB.replace(/\s/g, '').toLowerCase().split(',');
                    // return partsA.slice(0, -1).join(',') == partsB.slice(0, -1).join(',')
                    return partsA.join(',') == partsB.join(',')
                });
              
                return partialMatch;
            });

            let json = {
                id: responseId,
                tags: aWithDates,
                deleteTagss: deleteTag,
                isContact: true
            }
            // console.log("deepllll",deleteTag)
            resolve(json);

        }).catch((err) => {
            resolve(err?.response?.data?.email?.message)
        })
    })
}
export const leadGetApi = async (JsonObj, json) => {
    // console.log("sammmmmmmmmmmmmmm",JsonObj)
    const results = await GetResponse(JsonObj);
    // console.log("reasulttttttt",results)
    if (results == "The email address is invalid.") {
        let currentDate = new Date()
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // Months are 0-based, so add 1
        const day = currentDate.getDate();
        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        // let aWithDates = JsonObj.tag.map(item => `${item},${formattedDate}`);
        let aWithDates = JsonObj.tag.map(item => `${item}`);
        const data = await leadPostContactApi(json, aWithDates);
        return data
    } else {
        return results
    }

}
export const isNotValidNullUndefinedfile = (value) => {
    if (value !== undefined && value !== null && value !== '') {
      return true;
    }
    return false
  }
  export function capitalizeName(name) {
    let words = name.split(' ');

    let capitalizedWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    });
    let capitalizedName = capitalizedWords.join(' ');

    return capitalizedName;
}  